<script setup lang="ts">
import { exportLeaseToXlsx } from '~/composables/useAPI/useApiExport'
import type { ILeaseInfo } from '@register'
import { openNotesModal } from '@register/components/NotesModal/composables/useNotesModal'
import { IEntity } from '~/composables/useAPI/types'

import print from 'ink-html'

// PROPS
interface Props {
  leaseInfo: ILeaseInfo
  downloadingPdf?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  downloadingPdf: false,
})

// EMITS
const emit = defineEmits<{
  'download-pdf': []
  'download-xlsx': []
  print: []
}>()

// GLOBAL
const companyId = getCurrentDivisionId()

// PDF
const onDownloadPDF = async () => {
  emit('download-pdf')
}

// XLSX
const onDownloadXlsx = async () => {
  if (!props.leaseInfo.id || !props.leaseInfo.name) return
  try {
    await exportLeaseToXlsx(companyId, props.leaseInfo.id, props.leaseInfo.name)

    useAnalytics({
      name: 'Lease Summary: Download XLSX',
      showDivision: true,
    })
  } catch (error) {
    useAnalytics({
      name: 'Lease Summary: Download XLSX Error',
      showDivision: true,
      data: {
        message: error.message || error.name || error,
      },
    })
  }
  emit('download-xlsx')
}

// PRINT
const onPrint = () => {
  print(document.querySelector('#print-area'))

  // window.print()
  emit('print')
  useAnalytics({
    name: 'Lease Summary: Print Lease',
    showDivision: true,
  })
}

const onNotes = () => {
  openNotesModal(IEntity.LEASE, props.leaseInfo.id)
}

// LEASE MANGER
const onExportToLM = useModalExportToLM(companyId, props.leaseInfo, 'summary')
</script>

<template>
  <div class="flex w-full flex-col gap-2 p-2 sm:flex-row print:hidden">
    <Button
      v-if="leaseInfo.isPublished && leaseInfo.exportDetails?.canExport"
      color="secondary"
      class="flex justify-center whitespace-nowrap disabled:pointer-events-none disabled:opacity-50"
      size="sm"
      icon="share-arrow"
      @click="onExportToLM"
    >
      Export to Manager
    </Button>

    <!-- <Button
      color="secondary"
      class="flex justify-center whitespace-nowrap disabled:pointer-events-none disabled:opacity-50"
      :disabled="downloadingPdf"
      size="sm"
      :loading="downloadingPdf"
      icon="file-pdf"
      @click="onDownloadPDF"
    >
      Download PDF
    </Button> -->
    <Button
      color="secondary"
      size="sm"
      class="flex justify-center whitespace-nowrap"
      icon="document-excel"
      @click="onDownloadXlsx"
    >
      Download XLS
    </Button>

    <Button
      color="secondary"
      size="sm"
      class="flex justify-center whitespace-nowrap"
      icon="printer"
      @click="onPrint"
    >
      Print
    </Button>

    <Button
      color="secondary"
      size="sm"
      class="flex justify-center whitespace-nowrap"
      icon="document-edit"
      @click="onNotes"
    >
      Notes
      <span v-if="leaseInfo.notesCount" class="text-gray-400">
        ({{ leaseInfo.notesCount }})
      </span>
    </Button>
  </div>
</template>
