import { useQueryClient } from '@tanstack/vue-query'
import { useMutationExportToLM } from '@export'
import type { ILeaseInfo } from '@register'

export const useModalExportToLM = (
  divisionId: string,
  lease: ILeaseInfo,
  from: 'summary' | 'review',
) => {
  const queryClient = useQueryClient()
  const mixpanel = useMixpanel()
  const { mutateAsync: exportToLM } = useMutationExportToLM(divisionId)

  return useConfirmHandler(async () => exportToLM(lease.id!), {
    modal: {
      title: 'Export document to Manager',
      submitButton: 'Export',
      cancelButton: 'Cancel',
      confirmClass:
        'bg-primary focus:bg-primary hover:bg-primary-700 text-black hover:ring ring-primary ring-opacity-30',
    },
    loadingMessage: 'Exporting document...',
    successMessage: 'Document exported',
    errorMessage: 'Failed to export document',
    submitHandlerOptions: {
      beforeSubmit: () => {
        if (!divisionId || !lease || !canExport()) return
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['companies', divisionId, 'leases', lease.id, 'summary'],
        })

        // mixpanel.people?.increment('Total Files Exported to Lease Manager')
        // useAnalytics({
        //   name: 'Document exported',
        //   showDivision: true,
        //   data: {
        //     ID: lease.id!,
        //     from: title(from),
        //     divisionId,
        //   },
        // })
      },
    },
  })

  function canExport() {
    return !!lease.isPublished && !!lease.exportDetails?.canExport
  }
}
